import styled from 'styled-components'
import { ColumnView } from 'components/primitives/Views'
import { colors } from 'services/theme'

export const Screen = styled(ColumnView)`
  height: 100vh;
`

type ScreenContentProps = { background?: string }

export const ScreenContent = styled.div<ScreenContentProps>`
  background: ${props => props.background || colors.bg};
  flex: 1;
  height: calc(100vh - 5.5rem);
  overflow-y: auto;
  padding: 0 1rem 8rem 1rem;
`
