import React from 'react'
import { MenuScreen } from '../../App/shared/screens/MenuScreen'
import { FullCenterView } from '../../primitives/Views'
import { H1, Text } from '../../primitives/Text'
import { Section } from '../../primitives/Section'
import { RouteComponentProps } from '@reach/router'

export const Error404: React.FC<RouteComponentProps> = () => (
  <MenuScreen>
    <FullCenterView>
      <Section>
        <H1>We&apos;re sorry!</H1>
        <Text>We couldn&apos;t find what You&apos;re looking for.</Text>
      </Section>
    </FullCenterView>
  </MenuScreen>
)
