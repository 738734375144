import React from 'react'
import { ScreenContent } from './Screen'

interface Props {
  isHidden?: boolean
}

export const MenuScreen: React.FC<Props> = ({ children, isHidden }) => {
  return (
    <ScreenContent
      background='var(--card-page-bg)'
      style={{ ...(isHidden && { display: 'none' }) }}
    >
      {children}
    </ScreenContent>
  )
}
