import React from 'react'
import { Error404 } from 'components/App/Error404/Error404'
import { themes } from 'services/theme'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'components/shared/GlobalStyles'
import SEO from 'components/shared/SEO'

const NotFound: React.FC = () => {
  return (
    <ThemeProvider theme={themes.light}>
      <SEO title='Not found' />
      <GlobalStyles />
      <Error404 />
    </ThemeProvider>
  )
}

export default NotFound
